<template>
  <b-container
    fluid
    class="mb-5"
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="driver_name">
              <strong>Driver</strong>
            </label>
            <v-select
              id="driver_name"
              v-model="selected.driver"
              type="text"
              label="name"
              placeholder="search here"
              :options="list.drivers"
              :disabled="state.editing"
            >
              <template #option="{ name }">
                <strong>{{ name }}</strong>
              </template>
              <template #no-options="">
                No Available Driver
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <label for="date">
              <strong>Date From</strong>
            </label>
            <b-input
              id="date"
              v-model="tableHistories.filter.date_from"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <label for="date">
              <strong>Date To</strong>
            </label>
            <b-input
              id="date"
              v-model="tableHistories.filter.date_to"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <json-excel
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :class="[
              'btn btn-success', {
                'btn-outline-success disabled': state.exporting || !isValidForExport
              }
            ]"
            :fetch="exportProvider"
            :before-generate="startExport"
            :before-finish="finishExport"
            :fields="exportAs.excel.fields"
            :escape-csv="exportAs.excel.settings.escape"
            :type="exportAs.excel.settings.type"
            :name="excelFileName"
            :header="exportAs.excel.header"
          >
            <span
              v-if="state.exporting"
              class="align-middle"
            >Exporting <i class="ri-loader-2-line" /></span>
            <span
              v-else
              class="align-middle"
            >Export as Excel</span>
          </json-excel>
        </b-col></b-row>

      <b-table
        ref="table"
        class="mt-2"
        :per-page="tableHistories.perPage"
        :current-page="tableHistories.currentPage"
        :items="tableProvider"
        :fields="tableHistories.fields"
        :sort-by.sync="tableHistories.sortBy"
        :sort-desc.sync="tableHistories.sortDesc"
        :sort-direction="tableHistories.sortDirection"
        :filter="tableHistories.filter"
        :filter-included-fields="tableHistories.filterOn"
        show-empty
        hover
        responsive
      >
        <template #cell(index)="data">
          {{ tableHistories.currentPage * tableHistories.perPage - tableHistories.perPage + (data.index + 1) }}
        </template>
        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
        <template #cell(actions)="row">
          <b-button
            size="sm"
            @click="onView(row.item)"
          >
            View
          </b-button>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableHistories.perPage"
              :options="tableHistories.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableHistories.currentPage"
            :total-rows="tableHistories.totalRows"
            :per-page="tableHistories.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-driver-schedule-form"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      aria-hidden="false"
      modal-class="full-height"
    >
      <b-card no-body>

        <b-tabs
          active-nav-item-class="font-weight-bold text-uppercase text-success"
          content-class="scrollable"
        >
          <b-tab active>
            <template #title>
              <div class="text-md-nowrap">
                <i class="ri-file-text-line h4" />
                <b class="is-tab-active ml-1">
                  Details
                </b>
              </div>
            </template>
          </b-tab>

          <b-row class="p-2">

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label for="departure_date">
                  <strong>Departure Date</strong>
                </label>
                <b-input
                  id="departure_date"
                  v-model="driverSchedule.departure_date"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="2"
            >
              <b-form-group>
                <label for="departure_time">
                  <strong>Departure Time</strong>
                </label>
                <b-input
                  id="departure_time"
                  v-model="driverSchedule.departure_time"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label for="arrival_date">
                  <strong>Arrival Date</strong>
                </label>
                <b-input
                  id="arrival_date"
                  v-model="driverSchedule.arrival_date"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="2"
            >
              <b-form-group>
                <label for="arrival_time">
                  <strong>Arrival Time</strong>
                </label>
                <b-input
                  id="arrival_time"
                  v-model="driverSchedule.arrival_time"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            />

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label for="actual_arrival_date">
                  <strong>Actual Arrival Date</strong>
                </label>
                <b-input
                  id="actual_arrival_date"
                  v-model="driverSchedule.actual_arrival_date"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="2"
            >
              <b-form-group>
                <label for="actual_arrival_time">
                  <strong>Actual Arrival Time</strong>
                </label>
                <b-input
                  id="actual_arrival_time"
                  v-model="driverSchedule.actual_arrival_time"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="driver_name">
                  <strong>Driver Name</strong>
                </label>
                <b-input
                  id="driver_name"
                  v-model="driverSchedule.driver_name"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label for="driver_license_type">
                  <strong>License Type</strong>
                </label>
                <b-input
                  id="driver_license_type"
                  v-model="driverSchedule.driver_license_type"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label for="driver_license_number">
                  <strong>License Number</strong>
                </label>
                <b-input
                  id="driver_license_number"
                  v-model="driverSchedule.driver_license_number"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label for="driver_license_expiry">
                  <strong>License Expiry</strong>
                </label>
                <b-input
                  id="driver_license_expiry"
                  v-model="driverSchedule.driver_license_expiry"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="actual_by_name">
                  <strong>Actual By</strong>
                </label>
                <b-input
                  id="actual_by_name"
                  v-model="driverSchedule.actual_by_name"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="actual_at">
                  <strong>Actual At</strong>
                </label>
                <b-input
                  id="actual_at"
                  v-model="driverSchedule.actual_at"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

        </b-tabs>
      </b-card>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SLODriverSchedule, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'
import Ripple from 'vue-ripple-directive'
import JsonExcel from 'vue-json-excel'

export default {
  name: 'VSLODriverReservationHistories',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Driver Schedule Histories'
    }
  },

  components: {
    JsonExcel
  },

  directives: {
    Ripple
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false,
        exporting: false
      },
      list: {
        drivers: []
      },
      selected: {
        driver: null
      },
      driverSchedule: {
        id: 0,
        driver: 0,
        driver_name: '',
        driver_license_type: '',
        driver_license: '',
        driver_license_expiry: '',
        departure_date: '',
        departure_time: '',
        arrival_date: '',
        arrival_time: '',
        actual_arrival_date: '',
        actual_arrival_time: ''
      },
      tableHistories: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          driver: '',
          date_from: '',
          date_to: '',
          search: ''
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'driver_name' },
          { key: 'departure_at', formatter: this.dateTimeFormatterLong, sortable: true },
          { key: 'arrival_at', formatter: this.dateTimeFormatterLong, sortable: true },
          { key: 'actual_arrival_at', formatter: this.dateTimeFormatterLong, sortable: true }
        ]
      },
      exportAs: {
        excel: {
          header: 'Driver Schedule Histories',
          settings: {
            type: 'xls',
            escape: false
          },
          fields: {
            Driver: {
              field: 'driver_name'
            },
            'Driver License': {
              field: 'driver_license_number'
            },
            'Driver License Type': {
              field: 'driver_license_type'
            },
            'Driver License Registration Expiry': {
              field: 'driver_license_expiry'
            },
            'Departure At': {
              field: 'departure_at'
            },
            'Arrival At': {
              field: 'arrival_at'
            },
            'Actual Arrival At': {
              field: 'arrival_at'
            },
            'Actual By': {
              field: 'actual_by_name'
            },
            'Actual At': {
              field: 'actual_at'
            }
          }
        }
      }
    }
  },

  computed: {
    excelFileName () {
      const driverName = String(this.driverSchedule.driver_name || '').replaceAll(' ', '-')
      const validDateFrom = String(this.tableHistories.filter.date_from || '').replaceAll('-', '')
      const validDateTo = String(this.tableHistories.filter.date_to || '').replaceAll('-', '')

      return `Driver-Schedules-${driverName}-${validDateFrom}-${validDateTo}.xls`
    },

    isValidForExport () {
      const validDateFrom = ![null, '', ' '].includes(this.tableHistories.filter.date_from)
      const validDateTo = ![null, '', ' '].includes(this.tableHistories.filter.date_to)
      const hasSchedules = this.tableHistories.totalRows >= 1

      return validDateFrom && validDateTo && hasSchedules
    }
  },

  watch: {
    'selected.driver' (value) {
      this.tableHistories.filter.driver = value?.id || ''
    }
  },

  mounted () {
    core.index()
    this.getDrivers()
  },

  methods: {
    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_date_from: filter.date_from,
        filter_date_to: filter.date_to,
        filter_driver: filter.driver,
        filter_text: filter.search
      })

      return await SLODriverSchedule.histories(filters).then(
        ({ data }) => {
          this.tableHistories.totalRows = data.total_rows
          return data.items
        }
      ).catch(() => {
        this.tableHistories.totalRows = 0
        return []
      })
    },

    async exportProvider () {
      if (!this.isValidForExport) {
        return []
      }

      const filters = this.objectToUrl({
        filter_driver: this.tableHistories.filter.driver,
        filter_date_from: this.tableHistories.filter.date_from,
        filter_date_to: this.tableHistories.filter.date_to,
        filter_text: this.tableHistories.filter.search
      })

      return await SLODriverSchedule.exports(filters).then(
        ({ data }) => {
          return data
        }
      ).catch(() => {
        return []
      }).finally(() => {
        this.state.exporting = false
      })
    },

    startExport () {
      if (!this.isValidForExport) {
        return
      }

      this.state.exporting = true
    },

    finishExport () {
      this.state.exporting = false
    },

    async getDrivers () {
      SSharedList.getDrivers().then(
        ({ data }) => {
          this.list.drivers = data.map(
            driver => ({
              ...driver,
              name: `${driver.first_name} ${driver.last_name}`
            })
          )
        }
      ).catch(() => {
        this.list.drivers = []
      })
    },

    onView (item) {
      const departureDate = item.departure_at?.split(' ')[0]
      const departureTime = item.departure_at?.split(' ')[1]
      const actualArrivalDate = item.actual_arrival_at?.split(' ')[0]

      this.driverSchedule.id = item.id
      this.driverSchedule.driver_name = item.driver_name
      this.driverSchedule.driver = item.driver
      this.driverSchedule.driver_license_expiry = item.driver_license_expiry
      this.driverSchedule.driver_license = item.driver_license
      this.driverSchedule.driver_license_type = item.driver_license_type
      this.driverSchedule.departure_date = departureDate
      this.driverSchedule.departure_time = this.timeFormatterTwelveHours(item.departure_at)
      this.driverSchedule.arrival_date = departureTime
      this.driverSchedule.arrival_time = this.timeFormatterTwelveHours(item.arrival_at)
      this.driverSchedule.actual_arrival_date = actualArrivalDate
      this.driverSchedule.actual_arrival_time = this.timeFormatterTwelveHours(item.actual_arrival_at)
      this.driverSchedule.actual_by_name = item.actual_by_name
      this.driverSchedule.actual_at = item.actual_at

      this.$bvModal.show('modal-driver-schedule-form')
    }
  }
}
</script>
